define("discourse/plugins/discourse-anonymous-moderators/discourse/templates/connectors/user-card-post-names/anonymous-user-identity", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AnonymousIdentity @user={{this.user}} @noCard={{true}} />
  */
  {
    "id": "KO/Rtfyp",
    "block": "[[[8,[39,0],null,[[\"@user\",\"@noCard\"],[[30,0,[\"user\"]],true]],null]],[],false,[\"anonymous-identity\"]]",
    "moduleName": "discourse/plugins/discourse-anonymous-moderators/discourse/templates/connectors/user-card-post-names/anonymous-user-identity.hbs",
    "isStrictMode": false
  });
});